<template>
  <div class="list-page">
    <list-filter :shopData="shopData" :uploadFilter="ok" />
    <list-tabs
      v-model="shopData.tab"
      :tabs="tabArr"
      @tab-click="handleClick"
    ></list-tabs>

    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="shopData.tab === 'normal'">
        <el-button type="primary" size="small" @click="handleAddShop"
          >新增</el-button
        >
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="shopData.tab === 'deleted'">
        <el-button type="primary" size="small" @click="putBack">恢复</el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除</el-button
        >
      </div>

      <el-table
        :data="tbody"
        class="thead-light"
        v-if="shopData.tab === 'normal'"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column
          fixed
          type="selection"
          min-width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :min-width="shopData.tab === 'normal' ? '120' : '170'"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
            v-if="th.prop != 'logo'"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
            v-if="th.prop == 'logo'"
          >
            <template slot-scope="scope">
              <!-- logo -->
              <el-image
                style="width: 80px; height: 80px"
                :src="scope.row[th.prop]"
                fit="cover"
              >
              </el-image>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <el-table
        :data="tbody"
        class="thead-light"
        v-if="shopData.tab === 'deleted'"
        @selection-change="handleSelectionChange"
      >
        <!-- 勾选-->
        <el-table-column
          fixed
          type="selection"
          min-width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :min-width="shopData.tab === 'normal' ? '120' : '170'"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
            v-if="th.prop != 'logo'"
          >
          </el-table-column>

          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            align="center"
            v-if="th.prop == 'logo'"
          >
            <template slot-scope="scope">
              <!-- logo -->
              <el-image
                style="width: 80px; height: 80px"
                :src="scope.row[th.prop]"
                fit="cover"
              >
              </el-image>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="shopData.page"
      :change="changPage"
    />
  </div>
</template>

<script>
import listFilter from "../../components/ShopList/ListFilter";
import {
  shopList,
  shopDetail,
  shopSoftDelete,
  shopDel,
  shopPutBack,
} from "../../api/shop-list/list";
import Pagination from "@/base/components/Default/Pagination";
// import ListImage from '@/base/components/List/ListImage';
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      // TypeArr:[],
      loading: false, //加载
      // shopData: {
      //   shop_keyword: '', //店铺名称
      //   type: -1, // 店铺类型
      //   start_time: -1, //开始时间
      //   end_time: -1, //结束时间
      //   tab: 'normal', //当前筛选tab
      // },
      shopData: {
        keyword: "",
        category_id: "",
        create_start_time: -1,
        create_end_time: -1,
        tab: "normal",
        page: 1,
        page_size: 15,
        order_by: "create_time",
        is_desc: 1,
      },
      thead: [
        { label: "商家ID", prop: "id", minWidth: 150 },
        { label: "logo图", prop: "logo", minWidth: 180 },
        {
          label: "商家名称",
          prop: "name",
          minWidth: 160,
        },
        {
          label: "商家类型",
          prop: "categories_text",
          minWidth: 150,
        },
        { label: "创建时间", prop: "create_time", minWidth: 290 },
      ],
      tbody: [],
      pageData: {
        total: 0,
      },
      ids: [],
    };
  },
  mounted() {
    this.getShopList(this.shopData);
  },
  methods: {
    // 获取列表
    getShopList(data) {
      shopList(data).then((res) => {
        console.log(res);
        this.loading = false;
        this.tbody = res.data.data;
        this.pageData.total = res.data.total;
      });
    },
    handleClick(e) {
      this.loading = true;
      this.shopData.tab = e.name;
      this.getShopList(this.shopData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.ids = [];
      } else {
        this.ids = [];
        // 筛选id
        selection.forEach((item) => {
          this.ids.push(item.id);
        });
      }
    },
    // 筛选回调
    ok(e) {
      this.loading = true;
      this.shopData = { ...this.shopData, ...e, page: 1 };
      this.getShopList(this.shopData);
    },
    // 新增
    handleAddShop() {
      this.$router.push({
        name: "addShop",
      });
    },
    // 编辑
    edit(row) {
      // shopDetail({ id: row.id }).then((res) => {
      //   console.log('detail', res);
      // });
      this.$router.push({ name: "editShop", params: { id: row.id } });
    },
    // 删除
    del(row) {
      if (this.shopData.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.loading = true;
            this.ids.push(row.id);
            shopSoftDelete({ ids: this.ids }).then((res) => {
              if (res.msg == "删除成功") {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.ids = [];
              }
              this.getShopList(this.shopData);
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.ids.push(row.id);
            shopDel({ ids: this.ids }).then((res) => {
              this.loading = true;
              if (res.msg == "删除成功") {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
              this.ids = [];
              this.getShopList(this.shopData);
            });
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 恢复
    putBack() {
      if (this.ids.length) {
        this.$msgbox
          .confirm("确定要将选中的数据恢复吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.loading = true;
            shopPutBack({ ids: this.ids }).then((res) => {
              if (res.msg == "操作成功") {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.ids = [];
              }
              this.getShopList(this.shopData);
            });
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 批量删除
    handleDel() {
      if (this.ids.length) {
        if (this.shopData.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.loading = true;
              shopSoftDelete({ ids: this.ids }).then((res) => {
                console.log("23", res);
                if (res.msg == "删除成功") {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.ids = [];
                }
                this.getShopList(this.shopData);
              });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              shopDel({ ids: this.ids }).then((res) => {
                this.loading = true;
                if (res.msg == "删除成功") {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                }
                this.ids = [];
                this.getShopList(this.shopData);
              });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 分页查询
    changPage(e) {
      this.shopData = { ...this.shopData, ...e };
      this.getShopList(this.shopData);
    },
  },
  components: {
    listFilter,
    Pagination,
    // ListImage
  },
};
</script>

<style></style>
