<template>
  <LayoutFilter
    :toggleCollapseEnable="false"
    :onFilter="ok"
    :onReset="resetForm"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="店铺名称：" prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺类型：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="活动时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from '@/base/layout/LayoutFilter';
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker';
import {shopType} from "../../api/shop-list/list"
export default {
  props: {
    shopData: {
      type: Object,
    },
    uploadFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      rules: {},
      TypeArr: [],
    };
  },
  created(){
    shopType().then((res) => {
      this.TypeArr = res.data;
    });
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  computed: {
    form: {
      get() {
        return this.shopData;
      },
      set(val) {},
    },
    // type: {
    //   get() {
    //     return this.form.type == -1 ? '' : this.form.type;
    //   },
    //   set(val) {
    //     this.form.type = val;
    //   },
    // },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style>
</style>